/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| DECLARATION
|----------------
|
*/
$black: #000;
$very-dark-grey: #141414;
$dark-grey: #424242;
$grey: #4d4d4d;
$very-light-grey: #fafafa;
$white: #fff;
$white-opacity: rgba(255, 255, 255, 0.8);
$black-opacity: rgba(0, 0, 0, 0.6);

$black: #000000;
$green: #9ee7ad;
$light-grey: #f3f3f3;

/*
|
| DEFAULTS
|----------------
|
*/
$border-color: $grey;
$text-color: $very-dark-grey;
$default-background-overlay: $black-opacity;

$black: $black;
$grey: $grey;
$green: $green;
$light-grey: $light-grey;
