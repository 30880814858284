.page-loader {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: $white;
    display: none;

    &.active {
        display: flex;
    }

    .item-content {
        width: 100%;
        height: 100vh;
        text-align: center;
        .block-logo {
            width: inherit;
            img {
                height: 130px;
            }
        }
    }

    .item-loadbar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        margin: auto;

        .item-loadbar-inner {
            width: 100%;
            height: 100%;
            border-radius: 1px;
            background-color: $very-dark-grey;
            animation: loadbar 1.2s cubic-bezier(0.92, 0, 0.09, 1);
            transform-origin: left top;
        }
    }
}

@keyframes loadbar {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(0.7);
    }
}
