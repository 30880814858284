/*
|
| CMS
|------
*/
.cms {
    p,
    ul,
    ol,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &:not(:first-child) {
            margin-top: 1em;
        }
    }

    @if is-enabled("titles") {
        h1 {
            @extend .title-xxl;
        }
        h2 {
            @extend .title-xl;
        }
        h3 {
            @extend .title-lg;
        }
        h4 {
            @extend .title-md;
        }
        h5 {
            @extend .title-sm;
        }
        h6 {
            @extend .title-xs;
        }
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    ul,
    ol {
        padding-left: 1.25em;

        li {
            &:not(:first-child) {
                margin-top: 0.25em;
            }
        }
    }

    ul {
        li {
            list-style: disc;

            ul {
                li {
                    list-style: circle;
                }
            }
        }
    }

    ol {
        li {
            list-style: decimal;
        }
    }

    a:not([class]) {
        text-decoration: underline;
    }

    blockquote {
        position: relative;
        width: 80%;
        margin-right: 0;
        margin-left: auto;
        padding: 130px 0;
        font-size: 30px;
        line-height: 1.5em;
        font-weight: 300;
        @include media-breakpoint-down(md) {
            margin: auto;
        }
        &:before {
            content: "";
            position: absolute;
            background-image: url("../img/icon/quote-left.svg");
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            transform: translateX(-60px) translateY(-30px);
        }
        &:after {
            content: "";

            position: absolute;
            right: 0;

            background-image: url("../img/icon/quote-right.svg");

            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(0) translateY(-50px);

            @include media-breakpoint-down(md) {
                transform: translateX(60px) translateY(-50px);
            }
        }
    }
}

.page-offset {
    padding-top: 160px;
}

#block-banner {
    padding-top: 160px;
    height: 580px;
    background: #f3f3f3;
}

#block-more {
    background: #9ee7ad;
    text-align: center;
    .title {
        color: #000;
        font-size: 40px;
        font-family: Inter;
        font-weight: 500;
        @include media-breakpoint-down(md) {
            text-align: center;
            font-size: 28px;
        }
    }
    .text {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-family: Inter;
        line-height: 24px;
        width: 75%;
        margin: 0 auto 30px auto;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    .link {
        display: inline-flex;
        padding: 12px 16px;
        align-items: flex-start;
        gap: 8px;
        color: #000;
        font-size: 16px;
        font-family: Inter;
        line-height: 24px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #fff;
        transition: all ease 0.4s;
        &:hover {
            color: #fff;
            background-color: #e9438e;
            border: 1px solid #e9438e;
            transition: all ease 0.4s;
        }
    }
}

.bg-img {
    overflow: hidden;
    background-size: cover;
    transition: all ease 0.5s;
    .bg {
        transition: all ease 0.5s;
        background-position: center;
    }
    &:hover {
        .bg {
            transform: scale(1.05);
            transition: all ease 0.5s;
        }
    }
}

#page-cms {
    h1 {
        color: #000;
        text-align: center;
    }
    h2 {
        font-size: clamp(1.875rem, 2.7777777778vw + 0.5rem, 2.5rem) !important;
    }
}
