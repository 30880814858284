[data-split-text] {
    opacity: 0;

    &.split-ready {
        opacity: 1;
    }
}

[data-kira-item="split-lines"] {
    .split-line {
        overflow: hidden;
    }
}

[data-kira-item^="fade-in"] {
    opacity: 0;
}

[data-kira-item="counter"] {
    position: relative;

    &::before {
        visibility: hidden;

        content: attr(data-counter-goal);
    }

    .counter-value {
        display: flex;

        position: absolute;
        inset: 0;

        align-items: center;

        white-space: nowrap;
    }
}