/*
|---------------------------------------------------------------
| GRID
|---------------------------------------------------------------
| Set grid properties
|
|
*/
$grid-default: 12 !default;
$grid-col-gap: 30px !default;
$grid-row-gap: 30px !default;