/*
|--------------------
|      SINGLE
|--------------------
*/
.single {
    overflow-x: hidden;
    #block-banner {
        height: initial;
        .back {
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            &:after {
                content: "";
                width: 9px;
                height: 7px;
                display: inline-block;
                background-size: cover;
                margin: 11px 10px 0 6px;
                float: left;
                cursor: pointer;
                transition: all ease 0.4s;
                transform: rotate(-180deg);
                background-image: url("../img/icon/arr-green.svg");
            }
            &:hover {
                &:after {
                    content: "";
                    margin: 11px 16px 0 0;
                    transition: all ease 0.4s;
                }
            }
        }
        h1 {
            color: #000;
            text-align: center;
            font-family: Inter;
            font-size: 64px;
            font-style: normal;
            font-weight: 500;
            line-height: 96px;
            @include media-breakpoint-down(xl) {
                font-size: 42px;
                font-style: normal;
                font-weight: 500;
                line-height: 48px;
            }
            @include media-breakpoint-down(lg) {
                font-size: 38px;
                font-style: normal;
                font-weight: 500;
                line-height: 42px;
            }
        }
        .date {
            color: #060f23;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
        .place {
            float: left;
            margin-top: 4px;
            margin-right: 20px;
            color: var(--rose, #e9438e);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-decoration-line: underline;
            @include media-breakpoint-down(sm) {
                float: initial;
                margin-right: initial;
                margin-bottom: 15px;
                display: block;
            }
        }
        .terms {
            @include media-breakpoint-down(sm) {
                float: initial;
                display: inherit;
            }
        }
    }
}
