/*
|---------------------------------------------------------------
| Helpers
|---------------------------------------------------------------
| Define helper classes
|
|
*/


/*
|
| TEXT TRANSFORMS
|----------------
|
*/
@if is-enabled('text-transforms') {
    .tt-n { text-transform: none !important }
    .tt-u { text-transform: uppercase !important }
    .tt-l { text-transform: lowercase !important }
    .tt-c { text-transform: capitalize !important }
}


/*
|
| TEXT DECORATIONS
|----------------
|
*/
@if is-enabled('text-decorations') {
    .td-u { text-decoration: underline !important }
    .td-n { text-decoration: none !important }
}


/*
|
| FONT STYLES
|----------------
|
*/
@if is-enabled('font-styles') {
    .fs-i { font-style: italic !important }
    .fs-o { font-style: oblique !important }
    .fs-n { font-style: normal !important }
}


/*
|
| BACKGROUND COVER
|----------------
|
*/
@if is-enabled('background-images') {
    .bg-contain,
    .bg-cover {
        background-position: center center !important;
        background-repeat: no-repeat !important;

        &-top {
            @extend .bg-cover;
            background-position: center top !important;
        }

        &-bottom {
            @extend .bg-cover;
            background-position: center bottom !important;
        }
    }
    
    .bg-contain {
        background-size: contain !important;        
    }

    .bg-cover {
        background-size: cover !important;
    }
}

/*
|
| OVERLAY
|----------------
|
*/
@if is-enabled('overlay') {
    .overlay {
        position: absolute;
        inset: 0;
        z-index: 1;

        background-color: $default-background-overlay;

        + * {
            position: relative;
            z-index: 2;
        }
    }
}


/*
|
| OVERFLOWS
|----------------
|
*/
@if is-enabled('overflows') {
    .o-v { overflow: visible !important }
    .ox-v { overflow-x: visible !important }
    .oy-v { overflow-y: visible !important }
    .o-h { overflow: hidden !important }
    .ox-h { overflow-x: hidden !important }
    .oy-h { overflow-y: hidden !important }
}


/*
|
| POSITIONS
|----------------
|
*/
@if is-enabled('positions') {
    .p-r { position: relative !important }
    .p-a { position: absolute !important }
    .p-f { position: fixed !important }
    .p-s { position: static !important }
}


/*
|
| FLEX ALIGN
|----------------
|
*/
@if is-enabled('flex-aligns') {
    .ai-start { align-items: flex-start }
    .ai-end { align-items: flex-end }
    .ai-center { align-items: center }
    .ai-baseline { align-items: baseline }
    .ai-stretch { align-items: stretch }

    .as-start { align-self: flex-start }
    .as-end { align-self: flex-end }
    .as-center { align-self: center }
    .as-baseline { align-self: baseline }
    .as-stretch { align-self: stretch }
}


/*
|
| FLEX JUSTIFY
|----------------
|
*/
@if is-enabled('flex-justifys') {
    .jc-start { justify-content: flex-start }
    .jc-end { justify-content: flex-end }
    .jc-center { justify-content: center }
    .jc-between { justify-content: space-between }
    .jc-around { justify-content: space-around }
}


/*
|
| WHITE SPACES
|----------------
|
*/
@if is-enabled('white-spaces') {
    .ws-inherit { white-space: inherit }
    .ws-normal { white-space: normal }
    .ws-nowrap { white-space: nowrap }
    .ws-pre { white-space: pre }
    .ws-pre-wrap { white-space: pre-wrap }
    .ws-pre-line { white-space: pre-line }
}


/*
|
| POINTER EVENTS
|----------------
|
*/
@if is-enabled('pointer-events') {
    .pe-inherit { pointer-events: inherit }
    .pe-none { pointer-events: none }
    .pe-auto { pointer-events: auto }
}


/*
|
| ANIMATION OPTIMIZATION
|----------------
|
*/
@if is-enabled('optimize-animation') {
    .optimize-animation {
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        transform-style: preserve-3d;
        perspective: 1000;
        -webkit-perspective: 1000;
        will-change: transform;
    }
}


/*
|
| TRANSFORMS
|----------------
|
*/
@if is-enabled('transforms') {
    .transform-none { transform: none !important }
}


/*
|
| ABSOLUTE FULL
|----------------
|
*/
@if is-enabled('absolute-full') {
    .absolute-full {
        position: absolute;
        inset: 0;
    }
}


/*
|
| CLEAR
|----------------
|
*/
@if is-enabled('floats') {
    .clear { clear: both }
}


/*
|
| IMG FULL
|----------------
|
*/
@if is-enabled('images') {
    .img-full {
        display: block;
        max-width: inherit;
        width: 100%;
    }

    .img-cover { object-fit: cover }
    .img-contain { object-fit: contain }
}


/*
|
| W-100 H-100
|----------------
|
*/
@if is-enabled('width-height') {
    .w-100 { width: 100% }
    .h-100 { height: 100% }
}