/* Gravity form */
.gform_confirmation_message {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #0f1214;
    min-height: 600px;
    display: flex;
    vertical-align: middle;
    align-items: center;
}
.contact-form {
    padding: 30px 50px;
    background-color: #f3f3f3;
    @include media-breakpoint-down(md) {
        padding: 15px 15px 30px 15px;
    }
    .gravity-theme {
        h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 30px;
            letter-spacing: -0.02em;
            color: #fff;
            margin: 20px 0;
        }
        .validation_message {
            font-family: inherit;
            text-transform: inherit;
        }
        .validation_message {
            padding: 3px 10px;
        }
        .gform_ajax_spinner {
            margin-top: 5px;
        }
        .gform_submission_error {
            text-transform: initial;
            font-size: 15px;
            font-weight: 700;
            &:after {
                content: none;
            }
            span {
                display: none;
            }
        }
        .gform_required_legend {
            display: none;
        }
        .gform_validation_errors {
            margin: 50px 50px 0px 50px;
            position: relative;
            width: calc(100% - 60px);
            padding: 10px;
            box-shadow: none;
            border: 1px solid black;
            border-radius: initial;
            display: none;
        }
        .gform-icon {
            display: none !important;
        }
        .gform_title {
            margin: 30px 0;
        }
        .gform_body {
            h2 {
                @include media-breakpoint-down(xl) {
                    font-size: 38px;
                    line-height: 43px;
                }
                &:after {
                    content: none;
                }
            }
            .gfield_required {
                display: none !important;
            }
            .gform_required_legend {
                display: none;
            }
            .gfield {
                margin-bottom: 30px;
                font-style: normal;
                font-size: 15px;
                line-height: initial;
                color: #061c3d;
                a {
                    text-decoration: underline;
                }
            }
            .gfield_label {
                color: #060f23;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: 10px;
            }
            .ginput_container_date {
                .datepicker {
                    width: 100%;
                }
            }

            .gfield_date_dropdown_day,
            .gfield_date_dropdown_month,
            .gfield_date_dropdown_year {
                max-width: 33.33%;
            }
            input[type="text"],
            input[type="email"],
            input[type="tel"],
            select {
                color: #060f23;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-bottom: 1px solid #aab2b7;
                &:focus {
                    border-bottom: 1px solid red;
                }
                &::placeholder {
                    color: #060f23;
                }
            }

            [data-form-type] {
                &:focus {
                    border-bottom: 1px solid $green;
                }
                &::placeholder {
                    color: white;
                }
            }
            textarea {
                height: 254px;
                background-color: white;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border: none !important;
                color: #060f23;
                margin-top: 15px !important;
                padding: 20px !important;
                &:focus {
                    border: none !important;
                }
                &::placeholder {
                    color: white;
                }
            }
            select {
                position: relative;
                background-repeat: no-repeat;
                background-size: 8px;
                background-position: calc(100% - 20px) center;
                background-image: url(../img/icon/arr-form.svg);
            }
            .ginput_container {
                .gfield_consent_label {
                    color: #060f23;
                    font-family: Inter;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-left: 28px;
                    a {
                        text-decoration: underline;
                    }
                }
            }
            .ginput_container_consent {
                position: relative;
                a {
                    text-decoration: underline;
                }
                input[type="checkbox"] {
                    position: absolute;
                    cursor: pointer;
                    border: none;
                    left: 0;
                    top: 0px;
                    padding: 0 !important;
                    margin: 0;
                }

                input[type="checkbox"]:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 21px;
                    height: 21px;
                    top: 0;
                    left: 0;
                    border: 1px solid #060f23;
                    border-radius: 5px;
                    background-color: transparent;
                }
                input[type="checkbox"]:checked:before {
                    border: 1px solid #060f23;
                }
                input[type="checkbox"]:checked {
                    background-color: $green;
                    height: 20px;
                    width: 20px;
                    border-radius: 5px;
                }
                input[type="checkbox"]:checked:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 2px;
                    left: 7px;
                    border: solid white;
                    border-width: 0px 2px 2px 0;
                    display: inline-block;
                    padding: 3px 2px 8px 3px;
                    transform: rotate(45deg);
                }
            }
        }
        .gform_footer {
            padding: 0 !important;
            position: relative;
            margin: 30px auto;
            display: block !important;
            text-align: center;
            input[type="submit"] {
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

                display: block;
                width: fit-content;
                color: #000;
                border: 1px solid $green;
                transition: all ease 0.3s;
                border-radius: 8px;
                padding: 10px 20px;
                cursor: pointer;
                margin-top: 20px;
                margin: auto;
                background: $green;
                &:hover {
                    border: 1px solid $green;
                    transition: all ease 0.3s;
                    cursor: pointer;
                    background: transparent;
                }
            }
        }
        .iti.iti--allow-dropdown input {
            border: none !important;
        }
    }
}
.gform_wrapper.gravity-theme .gfield_error [aria-invalid="true"] {
    padding: 15px !important;
}

/* Custom */
.gform_wrapper {
    .gravity-theme {
        .gfield_label {
            font-family: "Neue Haas Grotesk Display Pro";
            font-style: normal;
            font-weight: 500 !important;
            font-size: 16px !important;
            line-height: 25px;
            color: #060f23;
        }
    }
}
