body {
    &.menu-active {
        overflow: hidden;
        .button_link {
            opacity: 0;
            display: none;
            transition: all ease 0.5s;
        }
        #header .btn-menu > .item-burger > span {
            background-color: #141414 !important;
            transition: all ease 0.5s;
        }
    }
}

/*
|
| Header
|---------
*/
%link-menu {
    text-transform: uppercase;
    color: $very-dark-grey;
    font-size: 14px;
    font-weight: 400;
}

#header {
    z-index: 21;
    display: block;
    position: fixed;
    transition: all ease 0.5s;
    width: 100%;
    background: white;

    &.top {
        padding: 0;
        background: transparent !important;
        @include media-breakpoint-down(lg) {
            padding: 0;
        }
        .item-logo {
            padding-block: 12px;
            transition: all ease 0.5s;
            img {
                height: initial;
                max-width: 255px;
                transition: all ease 0.5s;
                @include media-breakpoint-down(lg) {
                    margin-top: 0;
                }
            }
        }
    }
    .button_link,
    .item-link {
        color: black !important;
    }
    .header-container {
        display: flex;

        align-items: center;
        justify-content: space-between;

        .button_link {
            @include media-breakpoint-down(xl) {
                display: none !important;
                opacity: 0 !important;
                transition: all ease 0s !important;
            }
        }
        .item-link {
            color: #000 !important;
        }
        .item-logo {
            svg {
                display: block;

                path,
                polygon {
                    fill: $black;
                }
            }
        }

        .item-nav {
            display: none;

            .item-menu {
                display: flex;

                li {
                    &:not(:last-child) {
                        margin-right: 40px;
                    }

                    .item-link {
                        color: #000;
                        font-size: 16px;
                        font-family: Inter;
                        font-weight: 500;
                    }

                    &.cta {
                        .item-link {
                            display: none !important;
                        }
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                display: flex;
                align-items: center;

                .item-menu {
                    margin-right: 25px;
                }
            }
        }
    }

    .lang-container {
        position: relative;
        display: inline-flex;
        top: 1px;
        @extend %link-menu;
        cursor: pointer;

        ul {
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transform: translateX(10px);
            transition:
                opacity 1.2s $easeSmooth,
                transform 2s $easeSmooth;
        }

        &:hover {
            ul {
                opacity: 1 !important;
                visibility: visible !important;
                transform: translateX(0) !important;
            }
        }
    }

    .btn-menu {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 114px;

        &::after,
        &::before {
            content: none;
        }

        & > .item-burger {
            display: block;
            width: 25px;

            & > span {
                display: block;
                width: 100%;
                height: 2px;
                background-color: $very-dark-grey;
                border-radius: 2px;
                transition: all ease 0.5s;

                &:nth-child(2) {
                    margin: 6px 0;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
    display: flex;
    width: 100%;
    height: 100vh;

    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;

    padding: var(--header-height) var(--side-margin);

    align-items: center;

    background-color: $white;
    overflow: auto;
    opacity: 0;
    visibility: hidden;

    ul {
        margin: auto 0;

        li {
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            a {
                text-transform: uppercase;
                font-size: 1.875rem;
                font-weight: 500;
                color: $very-dark-grey;

                @include media-breakpoint-down(xs) {
                    font-size: 26px;
                }
            }
        }
    }

    .item-socials {
        display: flex;

        align-items: center;

        a {
            font-size: 25px;

            &:not(:last-child) {
                margin-right: 25px;
            }
        }

        @include media-breakpoint-down(xs) {
            a {
                font-size: 20px;
            }
        }
    }
}
