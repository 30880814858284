.block-banner {
    height: 100vh;
    min-height: 600px;
    @include media-breakpoint-down(sm) {
        min-height: initial;
    }
    //&::before {
    //    content: "";
    //    min-height: 600px;
    //    inset: 0;
    //    position: absolute;
    //    background-color: #175c25;
    //    opacity: 0.6;
    //    mix-blend-mode: hard-light;
    //    @include media-breakpoint-down(sm) {
    //        min-height: initial;
    //    }
    //}
}
