/*
|--------------------
|      NEWS
|--------------------
*/

#page-actualites-archive {
    h1 {
        color: #000;
        text-align: center;
    }
    .block-filters {
        vertical-align: middle;
        align-items: center;
        .desc {
            font-size: 18px;
            line-height: 28px;
            color: #37425e;
        }
        .block-filters {
            margin: auto;
            display: contents;
            .filters {
                margin: auto;
                display: flex;
                @include media-breakpoint-down(sm) {
                    float: left;
                    width: 100%;
                    display: block;
                }
                li {
                    color: #000;
                    text-align: center;
                    font-size: 16px;
                    font-family: Inter;
                    position: relative;
                    padding: 4px 25px;
                    margin: 0 10px;
                    cursor: pointer;
                    border-radius: 30px;
                    border: 1px solid rgba(88, 94, 237, 0.5);
                    @include media-breakpoint-down(sm) {
                        float: left;
                        text-align: center;
                        width: 100%;
                        margin: 10px 0 0 0;
                    }
                    &:hover,
                    &.active {
                        background-color: #e9438e;
                        border: 1px solid #e9438e;
                        color: white;
                        border-radius: 30px;
                    }
                }
            }
        }
    }

    .first {
        @include media-breakpoint-down(lg) {
            margin-bottom: 15px;
        }
        .custom-card {
            height: 390px;
            @include media-breakpoint-down(xl) {
                height: 290px;
            }
            .bg-img {
                height: 390px;
                width: 50%;
                float: left;
                @include media-breakpoint-down(xl) {
                    width: 100%;
                    height: 290px;
                    margin-bottom: 20px;
                }
                .bg {
                    height: 390px;
                    @include media-breakpoint-down(xl) {
                        height: 290px;
                    }
                }
            }
            .left-infos {
                width: 50%;
                float: left;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
                .infos-content {
                    display: block;

                    h2 {
                        color: #000;
                        font-size: 40px;
                        line-height: initial;
                        font-family: "Inter";
                        font-weight: 500;
                        @include media-breakpoint-down(xl) {
                            font-size: 28px;
                        }
                        @include media-breakpoint-down(lg) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    .text {
                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }
                    button {
                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
        &:hover {
            button {
                &:before {
                    content: "";
                    opacity: 1;
                    transition: all ease 0.4s;
                }
                &:after {
                    content: "";
                    opacity: 1;
                    margin: 0 0 0 12px;
                    transition: all ease 0.4s;
                }
            }
        }
        @include media-breakpoint-down(lg) {
            .dflex {
                display: block;
            }
        }
    }

    &.loading {
        position: relative;
        display: block;
        inset: 0;
        z-index: 2;
        opacity: 1;
        transition: all ease 0.3s;
        .custom-card {
            opacity: 0.5;
            transition: all ease 0.3s;
        }
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: 3;
        }
        &::after {
            z-index: 3 !important;
            display: block;
            width: 3.5rem;
            aspect-ratio: 1/1;

            position: absolute;
            top: calc(50% - 1.75rem);
            left: calc(50% - 1.75rem);
            z-index: 10;

            background-image: url(../img/icon/puff-white.svg);
            background-position: center;
            background-size: contain;

            content: "";
        }
    }
}

#block-similar-actus {
    .block-btn {
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
        .btn {
            &:hover {
                color: white;
            }
        }
    }
}

.load-more {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 10px 20px;
    color: white;
    margin: 70px auto;
    border-radius: 30px;
    background-color: #e9438e;
    border: 1px solid #e9438e;
    transition: all ease 0.4s;
    &::before,
    &::after {
        content: none !important;
    }
    &:hover {
        color: black;
        background: #9ee7ad;
        border: 1px solid #9ee7ad;
        transition: all ease 0.4s;
        &::before,
        &::after {
            content: none !important;
        }
    }
}
