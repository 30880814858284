/*
|-------------------------------
| Text align classes creation
|-------------------------------
| Variables used to generate text-align classes
| - Generated classes: .ta-{alignment}
| - Exemple: .ta-center
|
*/
$align-types: (
  'r': right,
  'l': left,
  'c': center,
  'j': justify
);


/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes" 
| - Generated classes: .ff-{family}
| - Exemple: .ff-default
|
*/
$font-families: (
    'default': $font-family-default,
    'custom':  $font-family-custom,
    'extra':   $font-family-extra
);


/*
|----------------------------------------------
| Font size classes creation
|----------------------------------------------
| Variables used to generate font size classes (including responsive)
| - Generated classes: .fs-{size}
| - Exemple: .fs-md
|
*/
$font-sizes: (
    'xs': (
        'default': $font-size-xs,
        'screens': (
            'xl': $screen-xl-font-size-xs,
            'lg': $screen-lg-font-size-xs,
            'md': $screen-md-font-size-xs,
            'sm': $screen-sm-font-size-xs,
        )
    ),
    'sm': (
        'default': $font-size-sm,
        'screens': (
            'xl': $screen-xl-font-size-sm,
            'lg': $screen-lg-font-size-sm,
            'md': $screen-md-font-size-sm,
            'sm': $screen-sm-font-size-sm,
        )
    ),
    'md': (
        'default': $font-size-md,
        'screens': (
            'xl': $screen-xl-font-size-md,
            'lg': $screen-lg-font-size-md,
            'md': $screen-md-font-size-md,
            'sm': $screen-sm-font-size-md,
        )
    ),
    'lg': (
        'default': $font-size-lg,
        'screens': (
            'xl': $screen-xl-font-size-lg,
            'lg': $screen-lg-font-size-lg,
            'md': $screen-md-font-size-lg,
            'sm': $screen-sm-font-size-lg,
        )
    ),
    'xl': (
        'default': $font-size-xl,
        'screens': (
            'xl': $screen-xl-font-size-xl,
            'lg': $screen-lg-font-size-xl,
            'md': $screen-md-font-size-xl,
            'sm': $screen-sm-font-size-xl,
        )
    ),
    'xxl': (
        'default': $font-size-xxl,
        'screens': (
            'xl': $screen-xl-font-size-xxl,
            'lg': $screen-lg-font-size-xxl,
            'md': $screen-md-font-size-xxl,
            'sm': $screen-sm-font-size-xxl,
        )
    )
);


/*
|-------------------------------
| Font weight classes creation
|-------------------------------
| How to use: add lines to create more "font weight classes"
| - Generated classes: .fw-{weight}
| - Exemple: .fw-400
|
*/
$font-weights : (
    '100': 100,
    '200': 200,
    '300': 300,
    '400': 400,
    '500': 500,
    '600': 600,
    '700': 700,
    '800': 800,
    '900': 900
);


/*
|-------------------------------
| Line height classes creation
|-------------------------------
| How to use: add lines to create more "line-height classes"
| - Generated classes: .lh-{weight}
| - Exemple: .lh-sm
|
*/
$line-heights: (
    'xs':  $line-height-xs,
    'sm':  $line-height-sm,
    'md':  $line-height-md,
    'lg':  $line-height-lg,
    'xl':  $line-height-xl,
    'xxl': $line-height-xxl
);


/*
|-------------------------------
| Letter spacing classes creation
|-------------------------------
| How to use: add lines to create more "letter-spacing classes"
| - Generated classes: .ls-{size}
| - Exemple: .ls-sm
|
*/
$letter-spacings: (
    'xs':  $letter-spacing-xs,
    'sm':  $letter-spacing-sm,
    'md':  $letter-spacing-md,
    'lg':  $letter-spacing-lg,
    'xl':  $letter-spacing-xl,
    'xxl': $letter-spacing-xxl
);