/*
|-------------------
| Ratio classes creation
|-------------------
| - Generated classes: .ratio-{size}
|
*/
$ratios: (
    'square':        '1/1',
    'almost-square': '1/.85',
    'landscape':     '1/.7',
    'landscape-sm':  '1/.6',
    'portrait':      '1/1.3'
);