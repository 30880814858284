/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Set paddings & margins sizes properties
|
|
*/
$xs:  8px;
$sm:  16px;
$md:  32px;
$lg:  64px;
$xl:  80px;
$xxl: 120px;