.custom-card {
    border-radius: 4px;
    transition: all ease 0.3s;
    .title-h2 {
        color: #000;
        font-size: 16px;
        font-family: "Inter";
        font-weight: 500;
        line-height: 24px;
        display: block;
    }
    .bg-img {
        width: 100%;
        height: 250px;
        overflow: hidden;
        display: block;
        background-color: #fff;
        border-radius: 40px 40px 40px 0px;
        .bg {
            height: 250px;
            width: 100%;
            display: block;
            transition: all ease 0.4s;
            transform: scale(1);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            background-color: white;
            &::before {
                content: "";
                inset: 0;
                position: absolute;
                background-color: #175c25;
                opacity: 0.6;
                mix-blend-mode: hard-light;
            }
        }
        &:hover {
            .bg {
                height: 250px;
                width: 100%;
                transition: all ease 0.4s;
                transform: scale(1.05);
            }
        }
    }
    .text {
        color: #000;
        font-size: 16px;
        font-family: "Inter";
        font-weight: 500;
        line-height: 24px;
        display: block;
    }
    .infos {
        display: flex;
        vertical-align: middle;
        align-items: center;
        width: 100%;
        .terms {
            text-align: left;
            float: left;
            margin-right: 15px;
        }
        .date {
            color: #000;
            font-size: 16px;
            font-family: "Inter";
            font-weight: 300;
            opacity: 0.5;
            display: block;
        }
    }
    &:hover {
        opacity: 0.8;
        transition: all ease 0.3s;
        .link {
            svg {
                margin-left: 20px;
                transition: all ease 0.3s;
            }
        }
    }
}
.terms {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    border-radius: 16px;
    color: #19303d;
    padding: 6px 20px;
    float: left;
    background-color: #e9438e;
    border: 1px solid #e9438e;
    color: white;
    border-radius: 30px;
}
