/*
|--------------------
|       Contact
|--------------------
*/

.page-template-contact {
    background-color: #f3f3f3;

    h1 {
        color: #fff;
        font-family: Inter;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include media-breakpoint-down(xl) {
            font-size: 32px;
        }
        @include media-breakpoint-down(lg) {
            font-size: 24px;
        }
    }
    .contact-adress {
        color: #fff;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @include media-breakpoint-down(xl) {
            font-size: 20px;
        }
        img {
            float: left;
        }
        span {
            float: left;
            display: block;
            margin-top: 5px;
            margin-left: 40px;
            @include media-breakpoint-down(xl) {
                margin-left: 10px;
            }
        }
    }
    .mrg {
        margin: 0 40px;
        width: 90%;
        @include media-breakpoint-down(xl) {
            margin: 0 30px;
        }
        @include media-breakpoint-down(lg) {
            margin: 0 15px;
        }
    }
    .img-bg {
        min-height: 700px;
        background-size: cover;
        background-repeat: no-repeat;
        height: calc(100vh - 184px);
        @include media-breakpoint-down(xl) {
            min-height: initial;
            height: initial;
            padding: 40px 0;
        }
    }
    h2 {
        color: #1c3e8e;
        font-family: Inter;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include media-breakpoint-down(xl) {
            font-size: 32px;
        }
    }
    .contact-form {
        @include media-breakpoint-down(md) {
            height: initial;
        }
        input[type="text"],
        input[type="email"],
        input[type="tel"],
        select {
            color: #1c3e8e;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: white;
            padding: 20px !important;
        }

        .gform_body [data-form-type] {
            border: none !important;
        }
        input[type="submit"] {
            margin: 40px auto !important;
        }
        .gfield {
            margin: 0 0 5px 0 !important;
            textarea.large {
                margin: 0 !important;
            }
        }
    }
}
