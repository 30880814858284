#block-join {
    background-color: #1c3e8e;
    h2 {
        color: #fff;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include media-breakpoint-down(md) {
            text-align: center;
            font-size: 28px;
        }
    }
    .item {
        .number {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            border: 1px solid #fff;
            text-align: center;
            margin: 30px auto;
            position: relative;
            @include media-breakpoint-down(md) {
                margin: 20px auto;
            }
            span {
                color: #e9438e;
                text-align: center;
                font-family: Inter;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: auto;
            }
        }
        .text {
            color: #fff;
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        &:not(:last-child) {
            .number {
                &:after {
                    content: "";
                    height: 1px;
                    width: 265px;
                    background-color: #fff;
                    position: absolute;
                    left: 62px;

                    @include media-breakpoint-down(xl) {
                        width: 185px;
                    }
                    @include media-breakpoint-down(lg) {
                        content: none;
                    }
                }
            }
        }
    }
}
