#block-team {
    h2 {
        color: #000;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include media-breakpoint-down(md) {
            text-align: center;
            font-size: 28px;
        }
    }
    .item {
        .icon {
            @include media-breakpoint-down(md) {
                text-align: center;
            }
            img {
                width: 100%;
                max-width: 280px;
                margin-bottom: 10px;
            }
        }
        .name {
            color: #1c3e8e;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .fonction {
            color: #e9438e;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
        }
        .description {
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }
}
