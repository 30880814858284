#block-partenaires {
    overflow: hidden;
    .swiper-slide {
        height: 75px;
        text-align: center;
        img {
            max-width: 120px;
            max-height: 60px;
        }
    }
}
