/*
|--------------------
|      NEWS
|--------------------
*/
// .filters {
//     &-list {
//         display: flex;
    
//         flex-wrap: wrap;
//         gap: 1rem;
//     }

//     &-item {
//         position: relative;
//     }

//     &-input {
//         position: absolute;
//     }

//     &-button {
//         display: block;

//         padding: .25rem 1rem;
//         border: 1px solid black;
//         border-radius: 100vmax;

//         cursor: pointer;

//         @at-root :checked + & {
//             background-color: black;

//             color: white;
//         }
//     }
// }

// .news {
//     &-container {
//         margin-top: 2.5rem;

//         &[data-loading=true] {
//             background-image: url(../img/svg-loaders/puff-black.svg);
//             background-repeat: no-repeat;
//             background-size: 2.5rem;
//             background-position: center;

//             & > * {
//                 visibility: hidden;
//             }
//         }
//     }

//     &-grid {
//         display: grid;

//         grid-template-columns: repeat(3, 1fr);
//         gap: 1.5rem;
//     }

//     &-item {
//         display: flex;
//     }

//     &-card {
//         display: flex;
//         width: 100%;

//         flex-direction: column;
//         justify-content: flex-start;
//         align-items: flex-start;

//         &-tags {
//             display: flex;

//             margin-bottom: .75rem;

//             flex-wrap: wrap;
//             gap: .5rem;
//         }

//         &-tag {
//             padding: .25rem .5rem;
//             border-radius: 100vmax;

//             background-color: black;
            
//             font-size: .75rem;
//             line-height: 1.2;

//             color: white;
//         }

//         &-image {
//             display: block;
//             width: 100%;
//             aspect-ratio: 1/.6;

//             margin-bottom: .5rem;
//         }

//         &-title {
//             margin-bottom: .75rem;

//             font-size: 1.125rem;
//             font-weight: 500;
//         }

//         &-link {
//             display: block;

//             margin-top: auto;
            
//             font-weight: 500;
//         }
//     }
// }

.load-more {
    display: flex;
    min-height: 2.5rem;

    margin-top: 2.5rem;

    justify-content: center;
    align-items: center;

    &[data-loading=true] {
        background-image: url(../img/svg-loaders/puff-black.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        & > * {
            visibility: hidden;
        }
    }
}