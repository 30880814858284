/*
|---------------------------------------------------------------
| TITLES
|---------------------------------------------------------------
| Set all title properties
|
|
*/

/*
|
| DEFAULTS
|----------------
|
*/
$default-titles: (
    font-family: $font-family-custom,
    font-weight: 400,
    line-height: 1.2,
    font-size: clamp(1.7rem, 3.8888888889vw + 0.5rem, 3.5rem) !important
);
