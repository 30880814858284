#footer {
    background: #f3f3f3;
    @include media-breakpoint-down(md) {
        text-align: center;
    }
    .title-ft {
        color: #000;
        font-size: 16px;
        font-family: Inter;
        font-weight: 500;
        line-height: 24px;
    }
    .icon {
        a {
            width: 64px;
            height: 64px;
            background: white;
            border-radius: 40px;
            display: flex;
            vertical-align: middle;
            align-items: center;
            margin-right: 15px;
            opacity: 1;
            transition: all ease 0.4s;
            @include media-breakpoint-down(md) {
                width: 30px;
                height: 30px;
            }
            &:hover {
                opacity: 0.8;
                background: #9ee7ad;
                transition: all ease 0.4s;
            }
            img {
                margin: auto;
            }
        }
    }
    .link {
        display: block;
    }
    .link-ft {
        display: block;
    }
    .link-col1 {
        font-weight: 500;
    }
    .block-copy {
        display: flex;
        border-top: 1px solid #ddd;
        justify-content: space-between;
        @include media-breakpoint-down(md) {
            display: block;
            text-align: center;
        }
    }
    a {
        transition: all ease 0.4s;
        &:hover {
            text-decoration: underline;
            transition: all ease 0.4s;
        }
    }
    @include media-breakpoint-down(md) {
        .ft-ico {
            margin: auto;
            display: inline-flex;
        }
    }
}
