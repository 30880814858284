#flex-galerie {
    .swiper-slide {
        height: 550px;
        width: 100%;
        background-size: cover;
        background-position: center;
        @include media-breakpoint-down(xl) {
            height: 370px;
        }
        @include media-breakpoint-down(lg) {
            height: 280px;
        }
        @include media-breakpoint-down(md) {
            height: 280px;
        }
        @include media-breakpoint-down(sm) {
            height: 200px;
        }
    }
}
