/*
|--------------------
|     PAGE HOME
|--------------------
*/
.home {
    #header.top {
        .button_link,
        .item-link {
            color: white !important;
        }
        .btn-menu > .item-burger > span {
            background-color: #e9e9e9;
        }
    }
}
