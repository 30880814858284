/* Plyr */
.videoyt {
    width: 100%;

    .plyr {
        .item-poster {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
            opacity: 1;
            transition: all ease 0.4s;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .plyr__control--overlaid {
        background-image: linear-gradient(90deg, black 0%, black 49%, black 100%);
        padding: 30px;
        @include media-breakpoint-down(md) {
            padding: 20px;
        }

        svg {
            color: white !important;
            height: 18px;
            width: 18px;
        }
    }

    .plyr__control--overlaid:hover {
        background-image: linear-gradient(90deg, black 0%, black 49%, black 100%);

        svg {
            color: white !important;
        }
    }

    .plyr--video {
        border-radius: 20px;
        background: transparent !important;
        filter: drop-shadow(0px 8px 22px rgba(149, 157, 165, 0.2));
        border-radius: 4px;
    }

    .plyr__video-wrapper {
        background: transparent !important;
    }

    .plyr--full-ui input[type="range"] {
        color: $grey;
    }

    .plyr__video-embed iframe {
        top: -50%;
        height: 200%;
        width: 101%;
    }

    .plyr__poster {
        background-size: cover;
    }

    .plyr__control--overlaid {
        background: white;
    }

    .plyr--video .plyr__control.plyr__tab-focus,
    .plyr--video .plyr__control:hover,
    .plyr--video .plyr__control[aria-expanded="true"] {
        background: grey;
    }

    .plyr__control.plyr__tab-focus {
        box-shadow: 0 0 0 5px rgba(black, 0.5);
    }

    .plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
        background: white;
    }
}

/* Custom */
.videoyt {
    .plyr__control--overlaid {
        background-color: $green;
        opacity: 1 !important;
        svg {
            color: white !important;
        }
        &:hover {
            background-color: white !important;
            svg {
                color: $green !important;
            }
        }
    }
}
