/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
* {
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

body {
    font-family: Inter;
    font-size: $default-font-size;
    font-weight: $default-font-weight;
    line-height: $default-line-height;
    color: $default-color;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

h2 {
    color: #000;
    font-size: 40px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @include media-breakpoint-down(md) {
        text-align: center;
        font-size: 28px;
    }
}

button,
input,
select,
textarea {
    border: 0;
    border-radius: 0;

    background-color: transparent;

    appearance: none;

    letter-spacing: inherit;
    font-family: inherit;
    font-size: inherit;
}

.dib {
    display: inline-block;
}

a,
button,
select,
input,
textarea {
    color: inherit;

    &:focus,
    &:focus-visible {
        outline: none;
        outline-offset: 0;
    }
}

a,
button {
    cursor: pointer;
}

.button_link {
    color: #000;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    position: relative;
    display: flex;
    vertical-align: middle;
    align-items: center;
    width: fit-content;
    opacity: 1;
    transition: all ease 0.5s;
    &:before {
        content: "";
        width: 100%;
        height: 2px;
        background: #9ee7ad;
        opacity: 0.3;
        display: block;
        position: absolute;
        bottom: -6px;
        transition: all ease 0.4s;
    }
    &:after {
        content: "";
        width: 9px;
        height: 7px;
        display: inline-block;
        background-size: cover;
        opacity: 0.3;
        margin: 0px 6px 0 6px;
        transition: all ease 0.4s;
        background-image: url("../img/icon/arr-green.svg");
    }
    &:hover {
        &:before {
            content: "";
            opacity: 1;
            transition: all ease 0.4s;
        }
        &:after {
            content: "";
            opacity: 1;
            margin: 0 0 0 12px;
            transition: all ease 0.4s;
        }
    }
}

a {
    text-decoration: none;
}

ol,
ul {
    li {
        list-style-type: none;
    }
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
    }
}
.m-a {
    margin: auto;
}
.w100 {
    width: 100%;
}
img {
    transition: opacity 0.4s ease;

    &[data-src] {
        opacity: 0;
    }
}

.ox-h {
    overflow-x: hidden;
}

.dflex {
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.txt-c {
    text-align: center;
}

.btn {
    color: #000;
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
    padding: 12px 16px;
    background: #9ee7ad;
    display: inline-block;
    transition: all 0.4s ease;
    &:hover {
        background: #82b98e;
        transition: all 0.4s ease;
    }
}
.justify-content-center {
    display: flex;
    justify-content: center;
}
