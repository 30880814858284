.hero {
    display: flex;
    width: 100%;
    min-height: 100vh;

    position: relative;
    align-items: center;
    
    padding-block: var(--header-height);
    
    background-color: $black;
    @if is-enabled('background-images') {
        @extend .bg-cover;
    }
}