#block-engagements {
    background: #f3f3f3;
    h2 {
        color: #000;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @include media-breakpoint-down(md) {
            text-align: center;
            font-size: 28px;
        }
    }
    .item {
        text-align: center;
        .icon {
            width: 88px;
            height: 88px;
            text-align: center;
            border-radius: 50%;
            background-color: #fff;
            margin: 40px auto 30px auto;
            img {
                max-width: 40px;
                max-height: 40px;
                margin: auto;
            }
        }
        .title {
            color: #1c3e8e;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .text {
            color: #333;
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }
}
