#block-values {
    .value {
        &.first {
            .bg-img {
                height: 580px;
                width: 100%;
                @include media-breakpoint-down(lg) {
                    height: 280px;
                }
                .bg {
                    height: 580px;
                    width: 100%;
                    position: relative;
                    @include media-breakpoint-down(lg) {
                        height: 280px;
                    }
                    &::before {
                        content: "";
                        inset: 0;
                        position: absolute;
                        background-color: #175c25;
                        opacity: 0.6;
                        mix-blend-mode: hard-light;
                    }
                }
                &:hover {
                    .bg {
                        transform: scale(1);
                    }
                }
            }
        }
        &.other {
            .bg-img {
                height: 300px;
                width: 100%;
                @include media-breakpoint-down(lg) {
                    height: 280px;
                }
                .bg {
                    height: 300px;
                    width: 100%;
                    position: relative;
                    @include media-breakpoint-down(lg) {
                        height: 280px;
                    }
                    &::before {
                        content: "";
                        inset: 0;
                        position: absolute;
                        background-color: #175c25;
                        opacity: 0.6;
                        mix-blend-mode: hard-light;
                    }
                }
                &:hover {
                    .bg {
                        transform: scale(1);
                    }
                }
            }
        }
        &.key-0 {
            .bg-img {
                border-radius: 40px 40px 0px 40px;
                overflow: hidden;
                position: relative;
                @include media-breakpoint-down(lg) {
                    border-radius: 40px 40px 0px 40px;
                }
            }
        }
        &.key-1 {
            .bg-img {
                border-radius: 40px 0px 40px 40px;
                @include media-breakpoint-down(lg) {
                    border-radius: 40px 40px 0px 40px;
                }
            }
        }
        &.key-2 {
            .bg-img {
                border-radius: 0px 40px 40px 40px;
                @include media-breakpoint-down(lg) {
                    border-radius: 40px 40px 0px 40px;
                }
            }
        }

        h3 {
            color: #1e1e1e;
            font-size: 24px;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .text {
            color: #1e1e1e;
            font-size: 16px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }
}
